import { translate } from 'translations/i18n';

export const routes = {
  homepage: '/',
  theTeam: '/#theTeam',
  ambassadors: '/#ambassadors',
  termsOfUse: '/terms-of-use',
  cookiePolicy: '/cookie-policy',
  privacyPolicy: '/privacy-policy',
  investment: '/investment',
  token: '/investment#tokenomics',
  newscenter: '/newscenter',
  inTheNews: '/news',
  rarissime: 'connectr.clapart.com',
  pressCenter: '/newscenter#presscenter',
  blog: '/articles',
  contact: '/contact',
  faq: '/faq',
  aboutUs: '/about-us',
  ecosystem: '/ecosystem',
};

export const routesConfig = [
  {
    title: translate('header.homeMenu'),
    url: routes.homepage,
    variant: 'dark',
    showInMenu: true,
    showInFooter: true,
    children: [
      {
        title: translate('header.home.theTeam'),
        url: routes.theTeam,
        variant: 'dark',
        showInMenu: false,
        showInFooter: true,
      },
      {
        title: translate('header.home.ambassadors'),
        url: routes.ambassadors,
        variant: 'dark',
        showInMenu: false,
        showInFooter: true,
      },
    ],
  },
  {
    title: translate('header.investmentMenu'),
    url: routes.investment,
    variant: 'dark',
    showInMenu: true,
    showInFooter: true,
    children: [
      {
        title: translate('header.presale.token'),
        url: routes.token,
        variant: 'dark',
        showInMenu: true,
        showInFooter: true,
      },
    ],
  },
  {
    title: translate('header.mediaMenu'),
    url: routes.newscenter,
    variant: 'light',
    showInMenu: true,
    showInFooter: true,
    children: [
      {
        title: translate('header.newscenter.inTheNews'),
        url: routes.inTheNews,
        variant: 'light',
        showInMenu: true,
        showInFooter: true,
      },
      {
        title: translate('header.newscenter.blog'),
        url: routes.blog,
        variant: 'light',
        showInMenu: true,
        showInFooter: true,
      },
    ],
  },
  {
    title: 'Ecosystem',
    url: routes.ecosystem,
    variant: 'dark',
    showInMenu: true,
    showInFooter: false,
  },
  {
    title: 'Rarissime',
    url: routes.rarissime,
    variant: 'dark',
    showInMenu: true,
    showInFooter: false,
    newPage: true
  },
  {
    title: 'About Us',
    url: routes.aboutUs,
    variant: 'dark',
    showInMenu: true,
    showInFooter: false,
  },
  {
    title: 'Contact',
    url: routes.contact,
    variant: 'dark',
    showInMenu: true,
    showInFooter: false,
  },
  {
    title: 'FAQ',
    url: routes.faq,
    variant: 'dark',
    showInMenu: true,
    showInFooter: false,
  },
  {
    title: translate('header.termsOfUse'),
    url: routes.termsOfUse,
    variant: 'dark',
    showInMenu: false,
    showInFooter: false,
  },
  {
    title: translate('footer.option3'),
    url: routes.cookiePolicy,
    variant: 'dark',
    showInMenu: false,
    showInFooter: false,
  },
];
