const dataChart = [
  {
    text: 'Tech Development',
    value: 40,
    color: '#5B468B',
  },
  {
    text: 'Ops',
    value: 20,
    color: '#7B5BBF',
  },
  {
    text: 'Marketing/Growth',
    value: 20,
    color: '#9A72E9',
  },
  {
    text: 'Liquidity',
    value: 15,
    color: '#9987BB',
  },
  {
    text: 'Working Capital Reserve',
    value: 5,
    color: '#CDBBEF',
  },
];

export default dataChart;
