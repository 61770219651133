import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import scrollIntoView from 'scroll-into-view';

import arrowDown from 'assets/icons/arrowDown.svg';
import heroVideo from 'assets/images/hero_page.mp4 (720p).mp4';
import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';
import { translate } from 'translations/i18n';
import { routes } from 'config/routesConfig';
import getWhitepaper from 'helpers/getWhitepaper';
import SectionSpacing from 'components/SectionSpacing';
import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const Hero = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [whitepaper, setWhitepaper] = useState();

  useEffect(() => {
    getWhitepaper().then((res) => {
      setWhitepaper(res);
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Container fluid className={styles.heroWrapper}>
        <ReactPlayer
          style={{
            position: 'absolute',
            top: 0,
            minWidth: '100%',
            minHeight: '100%',
            zIndex: '-1',
            overflow: 'hidden',
          }}
          width="fit-content"
          height="fit-content"
          playing={true}
          muted
          url={heroVideo}
          loop
          playsinline
        />
        <Container>
          {isMobile && <SectionSpacing height={260} />}
          <Row>
            <Col sm={{ offset: 2, span: 8 }}>
              <Typography variant="h1" className="mb-3">
                {translate('hero.title', {})}
              </Typography>
              <Typography className="text-center" color="blueGray">
                {translate('hero.subtitle')}
              </Typography>
            </Col>
          </Row>
          <Row className="text-center justify-content-center mt-1 m-sm-5">
            {/*<Col className="justify-content-end">*/}
            {/*  <Button*/}
            {/*    variant="primary"*/}
            {/*    className="mx-auto mx-xl-0 ms-xl-auto mb-3 mt-3 mt-sm-0"*/}
            {/*    onClick={() => {*/}
            {/*      navigate(routes.applyForWhiteList);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {translate('hero.joinButton')}*/}
            {/*  </Button>*/}
            {/*</Col>*/}
            <Col className="d-flex justify-content-center">
              <Button
                variant="secondary"
                className="mx-auto mx-xl-0 m-auto m-xl-0 mb-1 mt-4"
                onClick={() => {
                  window.open(whitepaper, '_blank');
                }}
              >
                {translate('hero.downloadButton')}
              </Button>
            </Col>
          </Row>
          <div className="text-center mt-5 pb-4">
            <SuspenseImg
              onClick={() => {
                scrollIntoView(document.getElementById('joinTheRevolution'), {
                  time: 500,
                  align: 'center',
                });
              }}
              className={`pointer ${styles.bounce}`}
              src={arrowDown}
            />
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default Hero;
