import React from 'react';
import { Container } from 'react-bootstrap';

import Typography from 'components/Typography';
import Timeline from 'components/Timeline';
import { financingTimelineData } from 'pages/Investment/sections/FinancingTimeline/financingTimelineData';
import { translate } from 'translations/i18n';

import styles from './styles.module.css';

const FinancingTimeline = () => {
  return (
    <Container className={styles.wrapper}>
      <Typography variant="caption" color="gradient" className="mb-3">
        {translate('presales.whatFutureHolds.pretitle')}
      </Typography>
      <Typography variant="h1" className={styles.titleSpacing}>
        {translate('presales.whatFutureHolds.title')}
      </Typography>
      <Timeline items={financingTimelineData} />
    </Container>
  );
};

export default FinancingTimeline;
