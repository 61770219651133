import React from 'react';

import { translate } from 'translations/i18n';

export const roadmapItems = [
  {
    pretitle: 'PHASE 01',
    title: 'Q4 2021',
    content: (
      <>
        <ul>
          <li>{translate('roadmap.phase1.productIdeationAndDevelopment')}</li>
          <li>{translate('roadmap.phase1.organizationalSetup')}</li>
          <li>{translate('roadmap.phase1.whitepaperRelease')}</li>
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 02',
    title: 'Q1 2022',
    content: (
      <>
        <ul>
          <li>{translate('roadmap.phase2.RSTProtocol')}</li>
          <li>{translate('roadmap.phase2.privateSale')}</li>
          <li>{translate('roadmap.phase2.creatorsOnboarded')}</li>
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 03',
    title: 'Q2 2022',
    active: false,
    content: (
      <>
        <ul>
          <li>{translate('roadmap.phase3.tokenTechnicalAuditCertik')}</li>
          <li>{translate('roadmap.phase3.protocolRelease')}</li>
          <li>{translate('roadmap.phase3.tokenIssuedOnBSC')}</li>
          <li>{translate('roadmap.phase3.presaleStart')}</li>
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 04',
    title: 'Q3 2022',
    active: false,
    content: (
      <>
        <ul>
          <li>{translate('phase4.presale')}</li>
          <li>{translate('phase4.integratedFiatRamps')}</li>
          <li>{translate('phase4.communityBuilding')}</li>
          <li>{translate('phase4.extendedMultiLanguageSupport')}</li>
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 05',
    title: 'Q4 2022',
    active: false,
    content: (
      <>
        <ul>
          <li>{translate('phase5.firstInternationalArtistsOnboarded')}</li>
          <li>{translate('phase5.corporatePartnerships')}</li>
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 06',
    title: 'Q1 2023',
    active: false,
    content: (
      <>
        <ul>
          <li>{translate('phase6.rarissimeNFT')}</li>
          <li>{translate('phase6.communityBuilding')}</li>
          <li>{translate('phase6.mintingCollection')}</li>
          {/*<li>{translate('phase6.productRelease')}</li>*/}
          {/*<li>{translate('phase6.crowdsaleOfTheToken')}</li>*/}
          {/*<li>{translate('phase6.firstRSTFinancingOfAnArtist')}</li>*/}
          {/*<li>{translate('phase6.DEXListing')}</li>*/}
          {/*<li>{translate('phase6.internalRSTMarketplace')}</li>*/}
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 07',
    title: 'Q2 2023',
    active: true,
    content: (
      <>
        <ul>
          <li>{translate('phase7.nftmarketplace')}</li>
          <li>{translate('phase7.metaversXCLP')}</li>
          {/*<li>{translate('phase7.automatedOnboarding')}</li>*/}
          {/*<li>{translate('phase7.newAreasOfContent')}</li>*/}
          {/*<li>{translate('phase7.RSTSecondaryTrading')}</li>*/}
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 08',
    title: 'H2 2023',
    active: true,
    content: (
      <>
        <ul>
          <li>{translate('phase8.releaseWeb')}</li>
          <li>{translate('phase8.rstFinancing')}</li>
          <li>{translate('phase8.dexListing')}</li>
          <li>{translate('phase8.cexListing')}</li>
        </ul>
      </>
    ),
  },
  {
    pretitle: 'PHASE 09',
    title: '2024',
    active: true,
    content: (
      <>
        <ul>
          <li>{translate('phase9.scaleWW')}</li>
          <li>{translate('phase9.artistOnboarding')}</li>
          <li>{translate('phase9.multiChain')}</li>
        </ul>
      </>
    ),
  },
];
