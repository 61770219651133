import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import beyondLogo from 'assets/images/partners/BeyondLogo.svg';
import uipath from 'assets/images/partners/uiPathLogo.svg';
import vdlawgrouplogo from 'assets/images/partners/vdlawgroupLogo2.svg';
import certiklogo from 'assets/images/partners/certikLogo.svg';
import nnalogo from 'assets/images/partners/NNALogo.svg';
import cryptocoin from 'assets/images/partners/cryptoCoinLogo.svg';
import arcstaking from 'assets/images/partners/ArcStakingLogo.svg';
import wam from 'assets/images/partners/wam.svg';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import SuspenseImg from 'components/SuspenseImage';
import GradientBG from 'components/GradientBG';
import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const partners = [
  {
    title: translate('partners.tehnicalPartner'),
    img: beyondLogo,
    url: 'https://beyondenterprizes.com/',
  },
  {
    title: translate('partners.automationDevelopment'),
    img: uipath,
    url: 'https://uipath.com/',
  },
  { title: translate('partners.tehnicalAudit'), img: certiklogo, url: 'https://www.certik.com/' },
  { title: translate('partners.ipLegalPartner'), img: nnalogo, url: 'https://nna-legal.ro/en/' },
  {
    title: translate('partners.legalCompliance'),
    img: vdlawgrouplogo,
    url: 'https://vdlawgroup.com/',
  },
  {
    title: translate('partners.paymentProcessor'),
    img: cryptocoin,
    url: 'https://cryptocoin.pro/',
  },
  {
    title: translate('partners.stakingPartner'),
    img: arcstaking,
    url: 'https://arcstake.com/',
  },
  {
    title: translate('partners.gamingPartner'),
    img: wam,
    url: 'https://wam.app/',
  }
];

const Partners = () => {
  const isMobile = useIsMobile();

  return (
    <Container className="position-relative">
      <GradientBG deskWidth={100} deskHeight={90} deg={-140} />
      <Typography variant="h1" className="mb-5">
        {translate('partners.title')}
      </Typography>
      <Container>
        <Row className={`d-flex justify-content-center g-1 ${isMobile ? 'g-1' : 'g-4'}`}>
          {partners.slice(0, 3).map((partner, index) => (
            <Col
              xs={5}
              lg={3}
              key={`partner${index}`}
              className="d-flex flex-column align-items-center mb-3"
              onClick={() => window.open(partner.url, '_blank')}
            >
              <div className={`${styles.imageContainer} ${styles.mainImageContainer}`}>
                <SuspenseImg src={partner.img} />
              </div>
              <Typography variant="body2">{partner.title}</Typography>
            </Col>
          ))}
        </Row>
        <Row className={`d-flex justify-content-center g-1 ${isMobile ? 'g-1' : 'g-5'}`}>
          {partners.slice(3, partners.length).map((partner, index) => (
            <Col
              xs={5}
              lg={3}
              key={`partner${index}`}
              className="d-flex flex-column align-items-center mb-3"
              onClick={() => window.open(partner.url, '_blank')}
            >
              <div className={styles.imageContainer}>
                <SuspenseImg src={partner.img} />
              </div>
              <Typography variant="body2">{partner.title}</Typography>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Partners;
