import { useEffect, useRef, useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useStoryblok } from '@storyblok/react';
import { Helmet } from 'react-helmet';

import Header from 'components/Header';
import Footer from 'components/Footer';
import FloatingBar from 'components/FloatingBar';
import Loader from 'components/Loader';
import DynamicComponent from 'storyblokComponents/DynamicComponent';

import Homepage from 'pages/Homepage';
import Investment from 'pages/Investment';
import useIsSticky from 'hooks/useIsSticky';
import { routes } from 'config/routesConfig';

import './App.css';

const ROOT_STORYBLOK_PATH = 'home';
const ROUTES_WITHOUT_LOADER = ['home', 'investment'];

const App = ({ pathname }) => {
  const [showFloatingBar, setShowFloatingBar] = useState(true);
  const [slug, setSlug] = useState(
    window.location.pathname === '/'
      ? ROOT_STORYBLOK_PATH
      : window.location.pathname.replace('/', ''),
  );

  const ref = useRef();
  const sticky = useIsSticky(ref, true);
  let story = useStoryblok(slug, { version: process.env.REACT_APP_STORYBLOK_VERSION });

  useEffect(() => {
    ReactGA.initialize('G-0Y4MSE24YG');

    if (!sessionStorage.getItem('showFloating') && sessionStorage.getItem('showFloating') !== '') {
      sessionStorage.setItem('showFloating', 'show');
      setShowFloatingBar(true);
    } else {
      const show = sessionStorage.getItem('showFloating') === 'show';
      setShowFloatingBar(show);
    }
  }, []);

  useEffect(() => {
    const newSlug = pathname === '/' ? ROOT_STORYBLOK_PATH : pathname.replace('/', '');
    setSlug(newSlug);
  }, [pathname]);

  const loaderVisible = useMemo(() => {
    if (ROUTES_WITHOUT_LOADER.includes(slug)) return false;
    return !story?.content;
  }, [slug, story]);

  const getTheme = story?.content?.theme
    ? story?.content?.theme
    : story?.content
    ? 'light'
    : 'dark';

  return (
    <div
      className="App"
      style={{ backgroundColor: story?.content && story?.content?.backgroundColor }}
    >
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-0Y4MSE24YG" />
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag() {
              window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-0Y4MSE24YG');`}
        </script>
        <script>
          {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '781267139742009');
                    fbq('track', 'PageView');`}
        </script>
      </Helmet>

      <div>
        <Header refProp={ref} sticky={sticky} theme={getTheme} />
        {loaderVisible ? <Loader /> : story?.content && <DynamicComponent blok={story?.content} />}
        <Routes>
          <Route path={routes.homepage} exact element={<Homepage />} />
          <Route path={routes.investment} exact element={<Investment />} />
        </Routes>
        {showFloatingBar && (
          <FloatingBar
            handleClose={() => {
              sessionStorage.setItem('showFloating', '');
              setShowFloatingBar(false);
            }}
            theme={getTheme}
          />
        )}
        {!loaderVisible && <Footer />}
      </div>
    </div>
  );
};

export default App;
