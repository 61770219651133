import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';

import styles from './styles.module.css';

const Counters = () => {
  return (
    <Container fluid className={styles.gradientBackground}>
      <Container>
        <Row>
          <Col className={`${styles.countColumn} ${styles.rightBorder}`} md={4}>
            <Row className="align-items-center h-100">
              <Col xs={8} sm={7}>
                <Typography align="left">{translate('counter.marketCap')}</Typography>
              </Col>
              <Col xs={4} sm={5}>
                <Typography variant="h2" align="right">
                  $50m
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col className={`${styles.countColumn} ${styles.rightBorder}`} md={4}>
            <Row className="align-items-center h-100">
              <Col xs={8} sm={7}>
                <Typography align="left">{translate('counter.artistsNumber')}</Typography>
              </Col>
              <Col xs={4} sm={5}>
                <Typography variant="h2" align="right">
                  +200
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col className={styles.countColumn} md={4}>
            <Row className="align-items-center h-100">
              <Col xs={8} sm={7}>
                <Typography align="left">{translate('counter.fansInvestorsNumber')}</Typography>
              </Col>
              <Col xs={4} sm={5}>
                <Typography variant="h2" align="right">
                  2.9TN
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Counters;
