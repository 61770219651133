import React, { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import TokenRewards from './sections/TokenRewards';
import Hero from './sections/Hero';
import PresaleProcess from './sections/PresaleProcess';
import TokenGenerationDistribution from './sections/TokenGenerationDistribution';
import UseOfFunds from './sections/UseOfFunds';
import FinancingTimeline from './sections/FinancingTimeline';
import RiskDisclaimer from './sections/RiskDisclaimer';
import Typeform from './sections/Typeform';
import InvestmentCalculator from './sections/InvestmentCalculator';
import UltimateBenefits from './sections/UltimateBenefits';
import scrollToSection from 'helpers/scrollToSection';
import renderPageSections from 'helpers/renderPageSections';
import Presales from '../Homepage/sections/Presales';
import PresalesCards from 'components/PresalesCards';
import { presalesCardsContent } from './sections/Hero/presalesCards';

const sections = [
  // <Hero />,
  // <Fragment>
  //   {/*<Presales presalePage />*/}
  //
  // </Fragment>,
  // <TokenRewards />,
    <br/>,
  <TokenGenerationDistribution />,
    <Fragment >
  <PresalesCards content={presalesCardsContent} />
    </Fragment>,
  // <InvestmentCalculator />,
  // <PresaleProcess />,
  <UltimateBenefits />,
  <UseOfFunds />,
  <FinancingTimeline />,
  // <Typeform />,
  <RiskDisclaimer />,
];

const Investment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scrollToSection(location.hash.replace('#', ''));
    } else {
      window?.scrollTo({ top: 0 });
    }
  }, [navigate, location]);

  return <div>{renderPageSections(sections)}</div>;
};

export default Investment;
