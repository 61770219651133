import { translate } from 'translations/i18n';

const dataChart = [
  {
    text: translate('presale.chartPie.sale'),
    value: 10.0,
    color: '#5B468B',
  },
  {
    text: translate('presale.chartPie.foundersTeam'),
    value: 9.84,
    color: '#7B5BBF',
  },
  {
    text: translate('presale.chartPie.advisors'),
    value: 4.33,
    color: '#9A72E9',
  },
  {
    text: translate('presale.chartPie.businessDevelopment'),
    value: 10.07,
    color: '#9987BB',
  },
  {
    text: translate('presale.chartPie.ecosystem'),
    value: 10.97,
    color: '#CDBBEF',
  },
  {
    text: translate('presale.chartPie.liquidityMiningReserves'),
    value: 4.79,
    color: '#CAB0FF',
  },
  {
    text: translate('presale.chartPie.staking'),
    value: 50,
    color: '#45378B',
  },
];

export default dataChart;
