import React, { useEffect, useState } from 'react';

import { translate } from 'translations/i18n';
import Typography from 'components/Typography';
import { PRESALE_END_DATE } from 'config/constants';

import styles from './styles.module.css';

const Countdown = () => {
  const [dateState, setDateState] = useState(new Date());

  const date2 = new Date(PRESALE_END_DATE);

  let delta = Math.abs(date2 - dateState) / 1000;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  const seconds = (delta % 60).toFixed();

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          {days}
        </Typography>
        <Typography variant="caption" color="blueGray">
          {translate('countdown.days')}
        </Typography>
      </div>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          :
        </Typography>
      </div>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          {hours}
        </Typography>
        <Typography variant="caption" color="blueGray">
          {translate('countdown.hours')}
        </Typography>
      </div>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          :
        </Typography>
      </div>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          {minutes}
        </Typography>
        <Typography variant="caption" color="blueGray">
          {translate('countdown.minutes')}
        </Typography>
      </div>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          :
        </Typography>
      </div>
      <div className={styles.rowBlock}>
        <Typography variant="h2" className="mb-2">
          {seconds}
        </Typography>
        <Typography variant="caption" color="blueGray">
          {translate('countdown.seconds')}
        </Typography>
      </div>
    </div>
  );
};

export default Countdown;
