import { lazy } from 'react';

const Page = lazy(() => import('storyblokComponents/SbPage'));
const Slider = lazy(() => import('storyblokComponents/SbSlider'));
const LogoRibbon = lazy(() => import('storyblokComponents/SbLogoRibbon'));
const Spacing = lazy(() => import('storyblokComponents/SbSpacing'));
const CardsSection = lazy(() => import('storyblokComponents/SbCardsSection'));
const ListBlog = lazy(() => import('storyblokComponents/SbListBlog'));
const NewsletterRibbon = lazy(() => import('storyblokComponents/SbNewsletterRibbon'));
const ImageSection = lazy(() => import('storyblokComponents/SbImageSection'));
const Post = lazy(() => import('storyblokComponents/SbPost'));
const SplitSection = lazy(() => import('storyblokComponents/SBSplitSection'));
const RecentArticles = lazy(() => import('storyblokComponents/SbRecentArticles'));
const FilterList = lazy(() => import('storyblokComponents/SbFilterList'));
const NewsletterCard = lazy(() => import('storyblokComponents/SbNewsletterCard'));
const ArticleCard = lazy(() => import('storyblokComponents/SbArticleCard'));
const HorizontalSlider = lazy(() => import('storyblokComponents/SbHorizontalSlider'));
const SocialShareCard = lazy(() => import('storyblokComponents/SbSocialShareCard'));
const PolicyHero = lazy(() => import('storyblokComponents/SbPolicyComponents/SbPolicyHero'));
const PolicySection = lazy(() => import('storyblokComponents/SbPolicyComponents/SbPolicySection'));
const IFrame = lazy(() => import('storyblokComponents/SbIFrame'));
const ShortCTA = lazy(() => import('storyblokComponents/SbShortCTA'));
const HorizontalAutoScrollList = lazy(() =>
  import('storyblokComponents/SbHorizontalAutoScrollList'),
);
const ScrollableSlider = lazy(() => import('storyblokComponents/SbScrollableSlider'));
const SocialCardRow = lazy(() => import('storyblokComponents/SbSocialCardRow'));
const Accordion = lazy(() => import('storyblokComponents/SbAccordion'));
const ContactForm = lazy(() => import('storyblokComponents/SbContactForm'));
const Text = lazy(() => import('storyblokComponents/SbText'));
const OurAmbassadors = lazy(() => import('storyblokComponents/SbOurAmbassadors'));
const DynamicColumnCard = lazy(() => import('storyblokComponents/SbDynamicColumnCard'));
const DynamicColumns = lazy(() => import('storyblokComponents/SbDynamicCols'));
const SplitSectionCard = lazy(() => import('storyblokComponents/SbSplitSectionCard'));
const FansCrazyness = lazy(() => import('storyblokComponents/SbFansCrazyness'));
const SectionHeading = lazy(() => import('storyblokComponents/SbSectionHeading'));
const RibbonCards = lazy(() => import('storyblokComponents/SbRibbonCards'));
const BoardOfAdvisors = lazy(() => import('storyblokComponents/SbBoardOfAdvisors'));
const Partners = lazy(() => import('storyblokComponents/SbPartners'));

// resolve Storyblok components React components
const Components = {
  page: Page,
  slider: Slider,
  logoRibbon: LogoRibbon,
  spacing: Spacing,
  cardsSection: CardsSection,
  listBlog: ListBlog,
  newsletterRibbon: NewsletterRibbon,
  imageSection: ImageSection,
  post: Post,
  splitSection: SplitSection,
  recentArticles: RecentArticles,
  newsletterCard: NewsletterCard,
  filterList: FilterList,
  articleCard: ArticleCard,
  horizontalSlider: HorizontalSlider,
  socialShareCard: SocialShareCard,
  policyHero: PolicyHero,
  policySection: PolicySection,
  iframe: IFrame,
  shortCTA: ShortCTA,
  horizontalAutoScrollList: HorizontalAutoScrollList,
  scrollableSlider: ScrollableSlider,
  socialCardRow: SocialCardRow,
  faq: Accordion,
  contactForm: ContactForm,
  fansCrazyness: FansCrazyness,
  text: Text,
  ourAmbassadors: OurAmbassadors,
  dynamicColumnCard: DynamicColumnCard,
  dynamicColumns: DynamicColumns,
  splitSectionCard: SplitSectionCard,
  sectionHeading: SectionHeading,
  ribbonCards: RibbonCards,
  boardOfAdvisors: BoardOfAdvisors,
  partners: Partners,
};

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component];

    return <Component blok={blok} key={blok._uid} />;
  }

  // fallback if the component doesn't exist
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

export default DynamicComponent;
