import React, { useCallback, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';

import useIsMobile from 'hooks/useIsMobile';
import Typography from 'components/Typography';

import styles from './styles.module.css';

let pos = { top: 0, left: 0, x: 0, y: 0 };

const Timeline = ({ items, ...rest }) => {
  const refTimelineWrapper = useRef();
  const isMobile = useIsMobile();

  const progressBarMoveHandler = useCallback((e) => {
    const maxScroll =
      refTimelineWrapper.current.scrollWidth - refTimelineWrapper.current.offsetWidth;
    const paginationWrapperWidth = document.getElementById('paginationWrapper').offsetWidth;
    const paginationElementWidth = document.getElementById('pagination').offsetWidth;
    const percentMax =
      ((paginationWrapperWidth - paginationElementWidth) / paginationWrapperWidth) * 100;
    const actualParentScroll =
      (refTimelineWrapper.current.scrollLeft / maxScroll) * 100 * (percentMax / 100);
    document.getElementById('pagination').style.left = `${actualParentScroll}%`;
  }, []);

  const mouseMoveHandler = useCallback(
    (e) => {
      const dx = e.clientX - pos.x;
      refTimelineWrapper.current.scrollLeft = pos.left - dx;
      progressBarMoveHandler(e);
    },
    [progressBarMoveHandler],
  );

  const mouseUpHandler = useCallback(() => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  }, [mouseMoveHandler]);

  const mouseDownHandler = useCallback(
    (e) => {
      pos = {
        left: refTimelineWrapper.current.scrollLeft,
        x: e.clientX,
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    },
    [mouseUpHandler, mouseMoveHandler],
  );

  useEffect(() => {
    refTimelineWrapper.current.addEventListener('mousedown', mouseDownHandler);
    refTimelineWrapper.current.addEventListener('touchstart', mouseDownHandler);
    refTimelineWrapper.current.addEventListener('scroll', progressBarMoveHandler);
  }, [mouseDownHandler, progressBarMoveHandler]);

  useEffect(() => {
    const currentIndex = 8;
    refTimelineWrapper.current.scrollLeft = isMobile
      ? (currentIndex - 1) * 300
      : (currentIndex - 2) * 450;
    const maxScroll =
      refTimelineWrapper.current.scrollWidth - refTimelineWrapper.current.offsetWidth;
    const paginationWrapperWidth = document.getElementById('paginationWrapper').offsetWidth;
    const paginationElementWidth = document.getElementById('pagination').offsetWidth;
    const percentMax =
      ((paginationWrapperWidth - paginationElementWidth) / paginationWrapperWidth) * 100;
    const actualParentScroll =
      (refTimelineWrapper.current.scrollLeft / maxScroll) * 100 * (percentMax / 100);
    document.getElementById('pagination').style.left = `${actualParentScroll}%`;
  }, [isMobile]);

  const renderTimelineItem = ({ pretitle, title, content, active }, index) => {
    return (
      <div key={index} className={`${styles.phaseWrapper} ${!active && styles.activePhase}`}>
        <div className={styles.line} />
        <Typography variant="label" color="gradient" className="gradientText mb-2">
          {pretitle}
        </Typography>
        <Typography variant="h2" align="left" className="mb-4">
          {title}
        </Typography>
        <Typography color="blueGray" align="left">
          {content}
        </Typography>
      </div>
    );
  };

  return (
    <Container {...rest}>
      <div className={styles.phasesWrapper} ref={refTimelineWrapper}>
        {items.map((item, index) => renderTimelineItem(item, index))}
      </div>
      <div id="paginationWrapper" className={styles.paginationWrapper}>
        <div id="pagination" className={styles.pagination} />
      </div>
    </Container>
  );
};

export default Timeline;
