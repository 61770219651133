import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { VolumeMuteFill, VolumeUp } from 'react-bootstrap-icons';

import videoUrl from 'assets/images/3282032670.mp4';
import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const Video = () => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0);

  const isMobile = useIsMobile();

  return (
    <Container>
      <motion.div
        className={styles.reactPlayerStyle}
        onViewportLeave={() => {
          setPlaying(false);
          setVolume(0);
        }}
        onViewportEnter={() => {
          setPlaying(true);
        }}
      >
        <ReactPlayer
          url={videoUrl}
          playing={playing}
          volume={volume}
          className={styles.reactPlayer}
          loop
          controls={isMobile}
          muted={volume === 0}
          width="100%"
          height="100%"
          playsinline
        />
        {!isMobile && (
          <div className={styles.muteWrapper}>
            {volume > 0 ? (
              <VolumeMuteFill
                className={styles.volumeIcon}
                onClick={() => {
                  setVolume(0);
                }}
              />
            ) : (
              <VolumeUp
                className={styles.volumeIcon}
                onClick={() => {
                  setVolume(0.5);
                }}
              />
            )}
          </div>
        )}
      </motion.div>
    </Container>
  );
};

export default Video;
