import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Hero from './sections/Hero';
import Presales from './sections/Presales';
import HowItWorks from './sections/HowItWorks';
import OurVision from './sections/OurVision';
import TheProblem from './sections/TheProblem';
import IntroducingClapArt from './sections/IntroducingClapArt';
import Roadmap from './sections/Roadmap';
import BoardOfStars from './sections/BoardOfStars';
import Partners from './sections/Partners';
import Video from './sections/Video';
import Newsletter from 'components/Newsletter';
import TheRoyaltyShare from 'pages/Homepage/sections/TheRoyaltyShare';
import scrollToSection from 'helpers/scrollToSection';
import renderPageSections from 'helpers/renderPageSections';
import Counters from 'components/Counters';

const sections = [
  <>
    <Presales />
    <Counters />
  </>,
  <Video />,
  <HowItWorks />,
  <OurVision />,
  <TheProblem />,
  <IntroducingClapArt />,
  <TheRoyaltyShare />,
  <Roadmap />,
  <BoardOfStars />,
  <Partners />,
  <Newsletter />,
];

const Homepage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scrollToSection(location.hash.replace('#', ''));
    } else if (location.state?.scrollToContact) {
      scrollToSection('contactSection');
    } else {
      window?.scrollTo({ top: 0 });
    }
  }, [navigate, location]);

  return (
    <>
      <Hero />
      {renderPageSections(sections)}
    </>
  );
};

export default Homepage;
