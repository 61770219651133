import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { translate } from 'translations/i18n';
import Countdown from 'components/Countdown';
import Typography from 'components/Typography';
import PresaleIsLive from 'pages/Homepage/sections/Rarissime';
import { routes } from 'config/routesConfig';
import SuspenseImg from 'components/SuspenseImage';
import countdownBgLeft from 'assets/images/countdownBgLeft.png';
import countdownBgRight from 'assets/images/countdownBgRight.png';
import countdownBgMobile from 'assets/images/countdownBgMobile.png';
import useIsMobile from 'hooks/useIsMobile';
import { PRESALE_END_DATE } from 'config/constants';

import styles from './styles.module.css';

const Presales = ({ presalePage }) => {
  const [dateState, setDateState] = useState(new Date());

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const date2 = new Date(PRESALE_END_DATE);
  let delta = date2 - dateState;

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);

  return (
    <>
      {delta > 0 ? (
        <Container id="joinTheRevolution" fluid className={styles.wrapper}>
          <div className={styles.topSection}>
            {!isMobile ? (
              <>
                <SuspenseImg src={countdownBgLeft} className={styles.bgLeft} />
                <SuspenseImg src={countdownBgRight} className={styles.bgRight} />
              </>
            ) : (
              <SuspenseImg src={countdownBgMobile} className={styles.bgMobile} />
            )}

            <SuspenseImg />
            <Container className={styles.joinTheRevWrapper}>
              <Row className={presalePage && styles.presalePage}>
                <Col className="text-center justify-content-center d-flex flex-column gap-3 gap-sm-4">
                  <Typography variant="label" color="gradient" className="mb-sm-2">
                    {translate('presales.pretitle')}
                  </Typography>
                  <Typography variant="h1">{translate('presales.title')}</Typography>
                  <Typography color="blueGray" className="px-4">
                    {translate('presales.text1')}
                    <b>{translate('presales.endDate')}</b>
                  </Typography>
                  <Typography color="blueGray" className="px-4">
                    {translate('presales.text2')}
                  </Typography>
                  <Countdown />
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(routes.applyForWhiteList);
                      }}
                    >
                      {presalePage
                        ? translate('presales.applyForWhitelistBtn')
                        : translate('presales.goToPresaleBtn')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      ) : (
        <PresaleIsLive />
      )}
    </>
  );
};

export default Presales;
