import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import Typography from 'components/Typography';
import SectionSpacing from 'components/SectionSpacing';
import { translate } from 'translations/i18n';

import styles from './styles.module.css';

const Rarissime = () => {
  return (
    <Container id="joinTheRevolution" fluid className={styles.wrapper}>
      <SectionSpacing />
      <Container className="text-center">
          <br/>
          <br/>
        <Button
          variant="primary"
          onClick={() => {
            window.open("https://www.connectr.clapart.com/", '_blank');
          }}
          className={styles.goToPresaleBtn}
        >
          {translate('presales.joinWEB')}
        </Button>
      </Container>
      <SectionSpacing />
    </Container>
  );
};

export default Rarissime;
