export const en = {
  hero: {
    title: 'Partner with the Stars. Win Together',
    subtitle:
      'CLAPART is a decentralized ecosystem where fans can invest in creative projects and creators can fund their work without artistic compromise.',
    joinButton: 'Buy Token',
    downloadButton: 'Download Whitepaper',
  },
  counter: {
    marketCap: 'Validated Valuation',
    artistsNumber: 'Confirmed Creators',
    fansInvestorsNumber: 'Global Entertainment & Media Market',
  },
  presales: {
    pretitle: 'Join the Revolution',
    title: 'PRESALE ENDS SOON',
    text1: 'CLAPART Presale ends on ',
    text2: 'Last chance to acquire the token at pre IDO discount!',
    titleClosed1: 'Presale is now closed. ',
    titleClosed2: 'Thank you!',
    textClosed1: 'Two financing rounds, private and public, have been successfully closed.',
    textClosed2: 'Please read more about the CLP token in our Whitepaper.',
    endDate: 'November 15!',
    applyForWhitelistBtn: 'Buy Token',
    downloadTokenomics: 'Tokenomics',
    joinDiscord: "JOIN DISCORD",
    joinWEB: "JOIN US",
    goToPresaleBtn: 'Go to Presale',
    whatFutureHolds: {
      pretitle: 'WHAT FUTURE HOLDS',
      title: 'Financing Timeline',
    },
  },
  countdown: {
    days: 'DAYS',
    hours: 'HOURS',
    minutes: 'MINUTES',
    seconds: 'SECONDS',
  },
  howItWorks: {
    pretitle: 'How CLAPART works',
    title: 'Creators & Fans \n Co-Create Value Together',
    title1: 'Creators & fans co-create',
    title2: 'value together',
    text: 'With CLAPART fans will be able to invest and support their favourite creators, becoming the modern day versions of art patrons. Creators can rely on our community to develop and promote their work.',
    artist: {
      firstArtistText:
        'Through CLAPART, Creators will be able to self-produce and crowdfund their work, allowing for unprecedented levels of artistic expression, with zero compromise. This makes CLAPART the perfect tool for emerging artists.',
      secondArtistText:
        'For the first time in history, fans will be able to directly invest into the works of their favorite artists, sharing with them the financial benefits through our innovative ROYALTY SHARE TOKEN (RST) Protocol.',
      thirdArtistText:
        'By tokenizing creations, we create a vibrant, liquid market where investors, fans and art critics join hands to create the most objective appraisal possible. Rich insights and instant feedback for unprecedented levels of engagement.',
      firstArtistTtitle: 'Creators Gain Freedom',
      secondArtistTitle: 'Fans Earn Royalties',
      thirdArtistTitle: 'Engaged Communities',
    },
  },
  ourVision: {
    pretitle: 'our Mission',
    title: 'To Break the Wall between Creators and Fans',
    text: 'We aim to create a decentralized ecosystem starting from a socially innovative platform for fans and creators. Musicians and actors, programmers or visual artists, writers and poets, gamers or designers, influencers, NFT creators and all visual artists will have the opportunity to launch their projects for public support, to produce their own works and have full control over their artistic expression. Similarly, fans and art lovers, investors and managers will have the opportunity to invest in today’s idols and tomorrow’s rising stars and then share the financial benefits from the success of their creative work.',
    readMoreBtn: 'Read More',
  },
  theProblem: {
    pretitle: 'The Problems',
    title: 'These Are Our Solutions \n for These Problems',
    text: 'With CLAPART, we aim to remove systemic challenges by rethinking the way art is created, valued and distributed',
    clapartSolution: 'CLAPART SOLUTION',
    firstTitle: 'Artists get only 12% of the total revenue',
    firstText: 'The E&M industry is rife with intermediaries.',
    firstSolution:
      'We aim to restructure how value is produced in the industry and put the creators in the center of the system.',
    secondHeading: 'Up to 9 months',
    firstHeading: '12c per $1',
    secondTitle: 'The average time for artists to get paid is 6-9 months ',
    secondText: 'The system is not just slow, but also bureaucratic.',
    secondSolution:
      'Fast, seamless payments and upfront sums for production which can truly change lives and offer unique chances.',
    thirdHeading: '-25% income',
    thirdTitle: 'Creator get less royalties than they should',
    thirdText:
      'Streaming platforms are under fire for distributing less money than creators deserve.',
    thirdSolution: 'We aim to create a fair and transparent art appraisal & royalty market.',
  },
  introClapart: {
    pretitle: 'THE SOLUTION',
    title: 'Introducing CLAPART APP',
    text: 'Our innovative app will offer fans and investors a seamless way of investing into their favorite artists and creators, combining the excitement of trading with the rich, engaging experience of a social network.',
    download: 'Coming Soon',
  },
  header: {
    menu: 'MENU',
    en: 'EN',
    ro: 'RO',
    tr: 'TK',
    hu: 'HU',
    es: 'ES',
    homeMenu: 'Home',
    investmentMenu: 'Investment',
    mediaMenu: 'News Center',
    home: {
      theTeam: 'The Team',
      ambassadors: 'Board of Stars',
    },
    presale: {
      token: 'Token',
      applyForWhitelist: 'Buy Token',
    },
    newscenter: {
      inTheNews: 'In the news',
      pressCenter: 'Presscenter',
      blog: 'Blog',
    },
    termsOfUse: 'Terms of Use',
  },
  royaltyShare: {
    title: 'The Royalty Share Token (RST) Protocol',
    text: 'This proprietary protocol was designed for the smooth crowdfunding of every creator and their work of art. The RST also acts as an automated system that distributes the royalties generated by the artwork to the token holders, based on their proportional contribution, instantaneously and without additional fees.',
  },
  roadmap: {
    phase: 'PHASE {{phaseNo}}',
    pageTitle: 'Roadmap',
    phase1: {
      productIdeationAndDevelopment: 'Product ideation & development',
      organizationalSetup: 'Organizational Setup',
      whitepaperRelease: 'Whitepaper Release',
    },
    phase2: {
      RSTProtocol: 'RST protocol launched',
      privateSale: 'Private sale',
      creatorsOnboarded: 'Over 200 creators onboarded',
    },
    phase3: {
      // websiteRelease: 'Website release',
      tokenTechnicalAuditCertik: 'Token Technical Audit (Certik)',
      tokenIssuedOnBSC: 'Token Issued on BSC',
      protocolRelease: 'RST protocol',
      presaleStart: 'Presale Start',
    },
  },
  boardOfStars: {
    pretitle: 'BOARD OF STARS',
    title: 'Proud CLAPART Ambassadors',
    text: `We are honored by the support and participation of some of the region's leading stars.`,
    button: 'Be the next megastar',
  },
  partners: {
    title: 'Partners',
    tehnicalPartner: 'Tehnical Partner',
    automationDevelopment: 'Automation Development',
    legalCompliance: 'Legal & Compliance',
    tehnicalAudit: 'Tehnical Audit',
    ipLegalPartner: 'IP Legal Partner',
    paymentProcessor: 'Payment processor',
    stakingPartner: 'Staking Partner',
    gamingPartner: 'Gaming Partner'
  },
  fansCrazyness: { dragToSwitch: 'DRAG TO SWITCH', pickEither: 'PICK EITHER' },
  teamOfTech: {
    title: 'A Team of Tech & Business Experts',
    text1:
      'A core team that blends experience and energy with highly specialised expertise in key business areas, working together to empower artists and creative industries.',
    text2:
      '+30 businesses | +100 entrepreneurial years | +300 brands managed | +5,000 events | +50 people working daily within the artistic ecosystem',
    seeLess: 'See less',
    seeMore: 'See more',
    pretitle: 'TEAM',
  },
  advisors: {
    bogdan: {
      position: 'VP of Product UiPath',
      description:
        'Bogdan founded his first start-up at 21. The company was acquired by Adobe, where he worked in senior engineering and product management roles. After 8 years with Adobe, he decided to join Vector Watch, to lead product management. Vector Watch was acquired by Fitbit where Bogdan worked as a Sr Director of Product Management. Bogdan is now with UiPath, as VP of Product Management.',
    },
    ioana: {
      position: 'CTO Beyond Enterprizes',
      description:
        'Technology strategy expert, with a proven track record in Software Product Management, Product Strategy, and Business Development, both in corporate and startup environments in Strategy, Operations, and Business Analysis. Built the 1st blockchain software development company in South-Eastern Europe at 22 years old.',
    },
    paul: {
      position: 'Chief of Technology Transfer @ ICI',
      description:
        'Dr. Paul Niculescu-Mizil Gheorghe is working together with ICI Bucharest. He graduated from UPB, Romania, specializing in Industrial Engineering and is active in the music world. Manager of the Executive Blockchain Laboratory. International collaborations and professional activities are with institutions like Old Dominion University, USA; ETH Zürich, CH a.o.',
    },
    alessandro: {
      position: 'Marketing Director & co-founder @ OOOH.Events',
      description:
        'Full stack marketer and business developer, spanning 30+ years across a range of industries in both international and domestic markets: event ticketing systems, software development, digital marketing, cinema saleshouse (Acme Adv S.p.A. co-founder and CEO), movie distribution (Nexo S.p.A. co-founder), event management.',
    },
    pretitle: 'Advisors',
    title: 'Board of Advisors',
    subtitle:
      'A core team that blends experience and energy with highly specialised expertise in key business areas, working together to empower artists and creative industries.',
  },
  footer: {
    title: 'Join the community',
    description:
      'Learn more about CLAPART, chat with the team, others in the community and have your say in shaping the future of the next megastars.',
    option1: 'FAQ',
    option2: 'Legal Notices',
    option3: 'Cookie policy',
    option4: 'Terms & Conditions',
    option5: 'Privacy Policy',
    copyrights: '© CLAPART 2023. All rights reserved.',
    londonAddress: '23 Berkeley Square | Mayfair | London | UK',
    bucharestAddress: '26 Avionului St | Bucharest | Romania',
    pressKit: 'PressKit',
  },
  joinNewsletter: {
    title: 'Join our newsletter',
    text: 'No spam. Only the latest CLAPART news worth your attention.',
    btn: 'Subscribe',
    inputPlaceholder: 'Email',
  },
  faq: {
    title: 'FAQ',
    text1: 'Didn’t find an answer to your question?',
    text2: 'Drop us a line here.',
    didntFindAnAnswer: 'Didn’t find an answer to your question? Drop us a line here.',
    btn: 'Ask a question',
    seeMore: 'SEE MORE',
    seeLess: 'SEE LESS',
  },
  contact: {
    title: 'Contact us',
    description:
      'If you have an issue, please head to our Discord server, post a detailed description in the support channel and provide your wallet address.',
    input1: 'First Name',
    input2: 'Last Name',
    input3: 'Email',
    input4: 'Phone Number',
    input5: 'Message',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    subject: 'Subject',
    selectATopic: 'Select a topic',
    text1: 'You agree to our',
    text2: 'terms of use.',
    button: 'Send',
    tooltipMsg: 'You must complete all fields and check the terms and conditions!',
    sent: 'Sent',
  },
  phase4: {
    presale: 'Presale',
    integratedFiatRamps: 'Integrated Fiat Ramps',
    communityBuilding: 'Community Building',
    extendedMultiLanguageSupport: 'Extended Multi-Language Support',
  },
  phase5: {
    firstInternationalArtistsOnboarded: 'First International Artists Onboarded',
    corporatePartnerships: 'Corporate Partnerships',
  },
  phase6: {
    rarissimeNFT: "Rarissime, exclusive NFT brand",
    communityBuilding: "Continue community building",
    mintingCollection: "First utility NFT collection minted",
    productRelease: 'Product Release (Alpha and Beta)',
    crowdsaleOfTheToken: 'Crowdsale of the Token',
    firstRSTFinancingOfAnArtist: 'First RST Financing of an Artist',
    DEXListing: 'DEX and CEX Listing',
    internalRSTMarketplace: 'Internal RST Marketplace',
  },
  phase7: {
    nftmarketplace: 'Rarissime NFT Marketplace',
    metaversXCLP: 'Deploy CLP on the MetaversX (Elrond) chain',
    automatedOnboarding: 'Automated Onboarding of Any Creator',
    newAreasOfContent: 'New Areas of Content',
    RSTSecondaryTrading: 'RST Secondary Trading',
  },
  phase8: {
    releaseWeb: 'Product release (web app and mobile app)',
    rstFinancing: 'First RST financing of an artist',
    dexListing: 'DEX listing (on both Binance and MetaversX)',
    cexListing: 'CEX listing',
    worldwideExpansion: 'Continued Worldwide Expansion',
    multichainExpansion: 'Multi-chain Expansion',
  },
  phase9: {
    launchCBS: `Launch CBS, the Creators' Blockchain`,
    scaleWW: `Worldwide scale-up`,
    artistOnboarding: `AI-powered artist onboarding`,
    multiChain: `Multi-chain architecture`,
  },
  presale: {
    header: {
      pretitle: 'INTRODUCING THE CLAPARTAPP',
      title: 'ClapArt Token presales has started',
      description:
        'Creating new markets for artistic appraisal with unprecedented levels of liquidity where artists, fans, investors and traders can seamlessly invest in current megastars or the bright rising of tomorrow.',
      button: 'Apply for Whitelist',
    },
    distribution: {
      pretitle: 'INTRODUCING THE CLAPARTAPP',
      title: 'Token generation & distribution',
      description:
        'Our mission is to create a revolutionary ecosystem where artists and other creators can seamlesly finance the production costs for their creation and share in the benefits with their fans, creating an unprecedented level of fanbase.',
      chart: 'Team',
    },
    tokenRewards: {
      legalTitle: "DON'T MISS THE PRESALE",
      title: 'CLAPART \n Token Rewards',
      description: 'Access different bonus levels, depending on the invested amount.',
      firstReward: '10% Bonus',
      secondReward: '20% Bonus',
      thirdReward: '40% Bonus',
      fourthReward: '60% Bonus',
      fifthReward: '65% Bonus',
      sixthReward: '70% Bonus,',
      firstInterval: '100-1k USDT',
      secondInterval: '1k-5k USDT',
      thirdInterval: '5k-10k USDT',
      fourthInterval: '10k-50k USDT',
      fifthInterval: '50k-100k USDT',
      sixthInterval: '100k-200k USDT',
      for: 'for',
      recommended: 'RECOMMENDED',
      leftLabel: 'If you are an',
      leftValue: 'Institutional Investor',
      rightLabel: 'If you plan to invest',
      rightValue: '>200k USDT',
      buttonText: 'Contact us for a personalized deal',
    },
    typeformSection: {
      pretitle: 'GET PREMIUM ACCESS',
      title: 'Buy Token',
      veriffWidgetTitle: 'Identity verification',
      startVerification: 'START VERIFICATION',
      description:
        'Verify is an identity verification provider that helps companies connect with customers.',
    },
    lastCall: {
      pretitle: "DON'T MISS THE CHANCE",
      title: 'Last call for bonus CLP',
      informativeText: 'Informative Text',
      applyForWhitelist: 'Apply for Whitelist',
    },
    benefits: {
      pretitle: 'REAL ECONOMY',
      title: 'Ultimate Benefits',
      description:
        "CLAPART token is build on top of a living, breathing economy of art appraisal and royalty sharing. We are continuously buying CLP from the market when we redistribute the royalties generated by the creators' artworks. This not only promotes a healthy and competitive market dynamic for the CLP token, but it grounds it in a real, stable economy",
      benefit1: 'Unique gateway to CLAPART ecosystem',
      benefit1Details:
        'Only with CLP can investors and fans acquire the RST generated for each individual artwork listed by Creators.',
      benefit2: 'Staking',
      benefit2Details:
        'With 39.8% of the total monetary mass allocated to staking over 50 years, CLAPART boasts a very strong staking system, offering attractive rewards to loyal users.',
      benefit3: 'Premium features',
      benefit3Details:
        'For token holders that choose to stake over certain amounts of CLP, we have special features such as Clap Gold and Clap Premium, allowing for premium experiences in our ecosystem. More details can be found in our Whitepaper.',
      benefit4: 'Deflationary tokenomics',
      benefit4Details:
        'We are proud of our tokenomics design. Among the key features is our deflationary approach, based on limited monetary mass and continous burning of tokens on each transaction.',
    },
    riskAndDisclaimer: {
      pretitle: 'Legal considerations',
      title: 'Risk & Disclaimer',
      description:
        'Please read the entirety of this “Legal Consideration, Risk & Disclaimer” section carefully.',
    },
    radColumns: {
      titleReward: 'LEGAL CONSIDERATIONS',
      title: 'Risk & Disclaimer',
      description:
        'Please read the entirety of this “Legal Consideration, Risk & Disclaimer” section carefully.',
      firstColumn:
        'The issuer is solely responsible for the content of this white paper. This whitepaper has not been reviewed or approved by any competent authority in any member state of the European Union or the United States. To the best knowledge of the issuer, the information presented in this white paper is correct and complete without any significant omission. <br/> <br/> The information shared in this whitepaper is not all-encompassing or comprehensive and does not in any way intend to create a direct or indirect contractual relationship. The primary purpose of this whitepaper is to provide potential token holders with pertinent information so as to help them thoroughly analyze the project and make an informed decision. The tokens may lose their value in part or in full, they may not always be transferable and may not be liquid. In addition, tokens may not be exchangeable against the goods or services promised in this whitepaper, especially in case of failure or discontinuation of the project.',
      secondColumn:
        'YOU MAY LOSE ALL MONIES THAT YOU SPEND PURCHASING CLAPART TOKENS AND YOUR PURCHASE CANNOT BE REFUNDED OR EXCHANGED. <br/> <br/> IF YOU ARE UNCERTAIN AS TO ANYTHING IN THIS WHITE PAPER OR YOU ARE NOT PREPARED TO LOSE ALL MONIES THAT YOU SPEND PURCHASING CLAPART TOKENS, WE STRONGLY URGE YOU NOT TO PURCHASE ANY CLAPART TOKENS. <br/> <br/> CLAPART TOKENS ARE NOT SHARES OR SECURITIES OF ANY TYPE. THEY DO NOT ENTITLE YOU TO ANY OWNERSHIP OR OTHER INTEREST IN CREATIVE BLOCKCHAIN SOLUTIONS SRL. THEY ARE MERELY A MEANS BY WHICH YOU MAY BE ABLE TO UTILISE CERTAIN SERVICES ON A PLATFORM THAT IS YET TO BE DEVELOPED. THERE IS NO GUARANTEE THAT THE PLATFORM WILL ACTUALLY BE DEVELOPED.',
    },
    stakingCalculator: {
      tooltip:
        '10% of your tokens will be released on 1st of November 2022, followed by a gradual release of 2.5% per week. ',
      allInvestors: 'Presale investors  have a 6 months cliff period.',
      transparentProcess: 'TRANSPARENT PROCESS',
      investmentCalculator: 'Investment Calculator',
      amountInvested: 'Amount Invested',
      applyForWhitelist: 'Buy Token',
      tokensReceived: 'Puchased Tokens',
      bonusTokens: 'Bonus Tokens',
      total: 'Total Tokens',
      bonusPercent: 'Bonus Percent:',
      effectivePrice: 'Blended effective price:',
      perClp: 'per CLP for $',
      perClpWithoutFor: 'per CLP',
    },
    chartPie: {
      coloredTitle: 'CLP TOKENOMICS',
      title: 'Token Generation and Distribution',
      description:
        'The entire monetary mass of 888.888.888 tokens will be pre-mined and used according to the following breakdown, with the aim to continuously develop the platform, the community and ecosystem by steering behaviour towards contribution and involvement.',
      sale: 'Sale',
      foundersTeam: 'Founders & Team',
      advisors: 'Advisors',
      businessDevelopment: 'Business Development',
      ecosystem: 'Ecosystem',
      liquidityMiningReserves: 'Liquidity Mining & Reserves',
      staking: 'Staking',
    },
    hero: {
      pretitle: 'INTRODUCING',
      pretitle2: 'THE CLP Token',
      title: 'The Presale is Live',
      text: 'Don’t miss your chance to invest in today’s idols and tomorrow’s rising stars, in exchange for royalties. Our innovative ecosystem can only be accessed through CLAPART (CLP), a utility and governance token offering unique mechanics and a competitive growth potential.',
    },
    presaleProcess: {
      pretitle: 'Presale Process',
      title: 'A Step-by-Step Guide for Getting your Tokens',
      text: 'Do you have any questions? Drop us a line',
      link: ' here',
      btn: 'Buy Token',
      step1Title: 'STEP 01 - Select your preferred payment method',
      step1Text1:
        'We allow for payment in both fiat and all the major coins and stablecoins. By using our partner’s',
      step1Text2:
        'widget, the flow for fiat payments has been greatly streamlined, offering a quick and intuitive way of purchasing your tokens.',
      step2Title: 'STEP 02 - Fill in our whitelisting form (crypto)',
      step2Text:
        'Our steamlined process will collect the necessary info for the SAFT (sales of future tokens) agreement. Your data is safe with us and will not be used for any other purpose, as per our privacy policy.',
      step3Title: 'STEP 03 - Veriff your identity (crypto)',
      step3Text:
        'After you fill in the form, you will receive an email with a Veriff link, that will walk you though a secure and easy process of verifying your ID.',
      step4Title: 'STEP 04 - Sign the SAFT (all payment methods)',
      step4Text:
        'After you go through the Veriff KYC process (or the CCPro payment flow in the widget), you will receive an email with your personalized Sales of Futures Tokens Agreement, that legally binds us to deliver your the tokens in accordance to the release scheme, once you make the payment.',
      step5Title: 'STEP 05 - Make the payment (crypto)',
      step5Text:
        'Once the SAFT is signed, you can make the payment, using the corresponding payment method you selected in the whitelisting form. Once our staff confirms your transaction (due to expected volumes, please allow up to 24 hours for the confirmation), you will receive a confirmation mail, with the detailed release ammounts and dates.',
      step6Title: 'STEP 06 - Get your tokens',
      step6Text:
        'As per our release strategy, there will be a 6 months cliff period. On 1st of November 2022, you will receive 10% of our tokens, with a gradual release scheme of additional 2,5% per week, until your entire ammount is released over a period of 11 months. Upon your approval, you will receive periodic newsletters with the evolution of our project. Thank you for investing in CLAPART!',
      step7Title: 'STEP 07 - Be part of the community',
      step7Text1: 'Congratulations! You are now a valued member of the CLAPART community. Join our',
      step7Text2: 'Channels and stay tuned to our project’s updates.',
    },
    useOfFunds: {
      pretitle: 'MAKE A DIFFERENCE',
      title: 'Use of Funds',
      text: 'All sums raised from token sales will be used for project advancement, with most resources allocated for tech:',
    },
    financingTimeline: {
      privateRound: 'In the private seed round our project was financed at a valuation of $50m',
      presale:
        'The Presale round opens June 1st and offers significant bonuses for early investors in CLAPART',
      exchangeListings:
        'We aim to offer significant liquidity to our token holders by listing on relevant, high volume centralized and decentralized exchanges.',
      publicSale:
        'A future public sale is scheduled to accompany the release of our full-feature app version.',
    },
    typeform: {
      eth: 'ETH',
      bnb: 'BNB',
      egld: 'EGLD',
      usdc: 'USDC',
      usdt: 'USDT',
      usd: 'USD',
      iWantToInvestUsing: 'I want to invest using:',
      eur: 'EUR',
      fiatOrStableCoins: 'Fiat or Stablecoins',
      crypto: 'Crypto',
    },
  },
  presalesCards: {
    smartContractAdress: {
      text1: 'Smart Contract Adress',
      text2: 'BSC Scan',
    },
    totalSupply: {
      text1: 'Total Supply',
      text2: '888,888,888',
    },
    auditedBy: {
      text1: 'Audited by',
    },
    blockchain: {
      text1: 'Blockchain',
      text2: 'Binance Smart Chain',
    },
  },
  ribbonCardsData: {
    card1: {
      text: '+30 businesses',
    },
    card2: {
      text: '+100 entrepreneurial years',
    },
    card3: {
      text: '300 brands managed',
    },
    card4: {
      text: '+5,000 events',
    },
    card5: {
      text: '+50 people working daily within the artistic ecosystem',
    },
  },
  logoRibbon: {
    title: 'As featured in',
  },
  slider: {
    previousBtn: 'PREVIOUS',
    nextBtn: 'NEXT',
  },
  podcasts: {
    tag: 'Category',
    title: 'In the loop Podcasts',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta maximus finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas.',
    buttonText: 'Coming soon',
  },
  events: {
    tag: 'Events',
    title: 'Join the fun',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porta maximus finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas.',
    buttonText: 'Coming soon',
  },
  cardsSection: {
    readMore: 'Read More',
    viewAllNews: 'View all news',
    seeMore: 'See More',
    moreNews: 'MORE NEWS',
  },
  recentBlogPosts: {
    title: 'Recent Blog posts',
  },
  inTheNews: {
    title: 'In The News',
  },
  newsletterRibbon: {
    title: 'Sign up for the newsletter!',
    subtitle: 'Be the first to hear the news and stay updated.',
    enterEmail: 'Enter your e-mail address',
    signUp: 'Sign Up',
    sent: 'Sent',
    message:
      'By clicking Sign Up you’ll receive occasional emails from ClapArt. You always have the choice to adjust your interest settings or unsubscribe.',
  },
  splitBox: {
    or: 'or',
  },
  filterList: {
    search: 'Search',
  },
  shareThisArticle: {
    title: 'Share this article',
  },
  percent: '%',
};
